canvas.full,
svg.z {
  width: 100%;
  height: 100%;
}

td {
  position: relative;
}

tr.strikeout td:not(:last-child):before {
  content: " ";
  position: absolute;
  top: 45%;
  left: 0;
  border-bottom: 3px solid rgba(190, 81, 0, 0.5);
  width: 100%;
}

div.chakra-alert__desc {
  line-height: 120%;
  padding-bottom: 0;
  padding-top: 0.3rem;
}

div.introjs-tooltiptext {
  color: #222;
  font-size: 1.6rem;
  line-height: 120%;
}
div.introjs-tooltiptext p {
  font-size: 1.6rem;
  line-height: 120%;
  padding-bottom: 0.8rem;
}
div.introjs-tooltiptext a {
  color: #22f;
}
.introjs-overlay {
  background: #555;
  opacity: 0.3;
}
div.introjs-tooltiptext p.soft {
  font-size: 1.4rem;
  line-height: 120%;
  color: #555;
}
@media (max-height: 70rem) {
  div.introjs-tooltiptext,
  div.introjs-tooltiptext p {
    padding-top: 0px;
    line-height: 110%;
  }
  div.introjs-tooltiptext {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  div.introjs-bullets {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (max-height: 60rem) {
  div.introjs-tooltiptext,
  div.introjs-tooltiptext p {
    font-size: 1.4rem;
    line-height: 110%;
  }
  div.introjs-tooltiptext {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div.introjs-bullets {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div.introjs-tooltiptext p.soft {
    font-size: 1.2rem;
    line-height: 120%;
  }
}
@media (max-width: 40rem) {
  div.introjs-tooltiptext,
  div.introjs-tooltiptext p {
    font-size: 1.2rem;
    line-height: 110%;
  }
  div.introjs-tooltiptext p.soft {
    font-size: 1rem;
    line-height: 110%;
  }
}
